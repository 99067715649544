import { consola } from 'consola'

export default defineNuxtPlugin((nuxtApp) => {
  const logger = consola.create({
    formatOptions:
        {
          date: false,
          columns: 2,
        },
  })

  nuxtApp.hook('vue:error', (..._args) => {
    logger.withTag('vue:error').fatal(..._args)
    // if (process.client) {
    //   console.log(..._args)
    // }
  })

  nuxtApp.hook('app:error', (..._args) => {
    logger.withTag('app').error(..._args)
    // console.log('app:error')
    // if (process.client) {
    //   console.log(..._args)
    // }
  })
  nuxtApp.vueApp.config.errorHandler = (..._args) => {
    logger.withTag('vue:errorHandler').fatal(..._args)
    // if (process.client) {
    //   console.log(..._args)
    // }
  }
})
