import revive_payload_client_LqaTI05mh5 from "/Users/huangkaisong/workspace/ichi-cake-moblie/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_sass@1.79.4_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_cFL2aaTIZ1 from "/Users/huangkaisong/workspace/ichi-cake-moblie/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_sass@1.79.4_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_FEzYE9Cqt3 from "/Users/huangkaisong/workspace/ichi-cake-moblie/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_sass@1.79.4_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_05rkydZUJP from "/Users/huangkaisong/workspace/ichi-cake-moblie/node_modules/.pnpm/nuxt-site-config@2.2.21_rollup@4.24.0_vite@5.4.8_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_G1itNpiK9M from "/Users/huangkaisong/workspace/ichi-cake-moblie/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_sass@1.79.4_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_XKKcis6iO6 from "/Users/huangkaisong/workspace/ichi-cake-moblie/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_sass@1.79.4_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ZfitzkGarS from "/Users/huangkaisong/workspace/ichi-cake-moblie/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_sass@1.79.4_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_N3pAxoMtOl from "/Users/huangkaisong/workspace/ichi-cake-moblie/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_sass@1.79.4_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_3l8qyis5KX from "/Users/huangkaisong/workspace/ichi-cake-moblie/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/huangkaisong/workspace/ichi-cake-moblie/.nuxt/components.plugin.mjs";
import prefetch_client_xdi399UYi7 from "/Users/huangkaisong/workspace/ichi-cake-moblie/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_sass@1.79.4_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_gOrY24njur from "/Users/huangkaisong/workspace/ichi-cake-moblie/node_modules/.pnpm/@nuxt+image@1.8.1_rollup@4.24.0/node_modules/@nuxt/image/dist/runtime/plugin.js";
import plugin_yc9QMC4cbQ from "/Users/huangkaisong/workspace/ichi-cake-moblie/node_modules/.pnpm/pinia-plugin-persistedstate@4.1.1_@pinia+nuxt@0.5.5_pinia@2.2.4_rollup@4.24.0/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import switch_locale_path_ssr_4XlJQxy6fH from "/Users/huangkaisong/workspace/ichi-cake-moblie/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_SaKHGJMSOE from "/Users/huangkaisong/workspace/ichi-cake-moblie/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import unocss_MzCDxu9LMj from "/Users/huangkaisong/workspace/ichi-cake-moblie/.nuxt/unocss.mjs";
import plugin_BncEGiwCHa from "/Users/huangkaisong/workspace/ichi-cake-moblie/modules/dayjs/runtime/plugin.ts";
import auth_vT9JWWT9pN from "/Users/huangkaisong/workspace/ichi-cake-moblie/plugins/auth.ts";
import error_ldt3PQauZ0 from "/Users/huangkaisong/workspace/ichi-cake-moblie/plugins/error.ts";
import router_client_W01CLn04zL from "/Users/huangkaisong/workspace/ichi-cake-moblie/plugins/router.client.ts";
import routeMiddleware_RObE1TNCEF from "/Users/huangkaisong/workspace/ichi-cake-moblie/plugins/routeMiddleware.ts";
import index_o5sMr0GrPF from "/Users/huangkaisong/workspace/ichi-cake-moblie/plugins/vant/index.ts";
export default [
  revive_payload_client_LqaTI05mh5,
  unhead_cFL2aaTIZ1,
  router_FEzYE9Cqt3,
  _0_siteConfig_05rkydZUJP,
  payload_client_G1itNpiK9M,
  navigation_repaint_client_XKKcis6iO6,
  check_outdated_build_client_ZfitzkGarS,
  chunk_reload_client_N3pAxoMtOl,
  plugin_vue3_3l8qyis5KX,
  components_plugin_KR1HBZs4kY,
  prefetch_client_xdi399UYi7,
  plugin_gOrY24njur,
  plugin_yc9QMC4cbQ,
  switch_locale_path_ssr_4XlJQxy6fH,
  i18n_SaKHGJMSOE,
  unocss_MzCDxu9LMj,
  plugin_BncEGiwCHa,
  auth_vT9JWWT9pN,
  error_ldt3PQauZ0,
  router_client_W01CLn04zL,
  routeMiddleware_RObE1TNCEF,
  index_o5sMr0GrPF
]