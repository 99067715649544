export default defineNuxtPlugin((_nuxtApp) => {
  const router = useRouter()

  // 覆盖 router.back() 方法
  const originalRouterBack = router.back
  router.back = () => {
    if (window.history.state?.back) {
      originalRouterBack()
    }
    else {
      router.push('/')
    }
  }

  // 覆盖 history.back() 方法
  history.back = () => {
    if (!window.history.state?.back) {
      navigateTo('/', { replace: true })
    }
    else {
      originalRouterBack()
    }
  }
})
