function getPrecision(num: number) {
  const str = String(num)
  const decimalIndex = str.indexOf('.')
  return decimalIndex === -1 ? 0 : str.length - decimalIndex - 1
}
// 处理出现科学计数法
function transferToNumber(num: any) {
  if (Number.isNaN(num)) {
    return num
  }
  num = `${num}`
  num = Number.parseFloat(num)
  const eformat = num.toExponential() // 转换为标准的科学计数法形式（字符串）
  const tmpArray = eformat.match(/\d(?:\.(\d*))?e([+-]\d+)/) // 分离出小数值和指数值
  const number = num.toFixed(Math.max(0, (tmpArray[1] || '').length - tmpArray[2]))
  return number
}

const math = {
  /** 加法运算 */
  add(a: number, b: number) {
    const precision = Math.max(getPrecision(a), getPrecision(b))
    const multiplier = 10 ** precision
    const result = (Math.round(a * multiplier) + Math.round(b * multiplier)) / multiplier
    return transferToNumber(result)
  },

  /** 减法运算 */
  subtract(a: number, b: number) {
    return transferToNumber(math.add(a, -b))
  },

  /** 乘法运算 */
  multiply(a: number, b: number) {
    const precision = getPrecision(a) + getPrecision(b)
    const multiplier = 10 ** precision
    const result = (Math.round(a * multiplier) * Math.round(b * multiplier)) / (multiplier * multiplier)
    return transferToNumber(result)
  },

  /** 除法运算 */
  divide(num1: number, num2: number) {
    const str1 = Number(num1).toString()
    const str2 = Number(num2).toString()
    let str1Length
    let str2Length
    // 解决整数没有小数点方法
    try {
      str1Length = str1.split('.')[1].length
    }
    catch (error) {
      console.error(error)
      str1Length = 0
    }
    try {
      str2Length = str2.split('.')[1].length
    }
    catch (error) {
      console.error(error)
      str2Length = 0
    }
    const step = 10 ** Math.max(str1Length, str2Length)
    const result = (num1 * step) / (num2 * step)
    return transferToNumber(result)
  },
}

export default math
