import { default as activatevlGH9R3taSMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/[type]/activate.vue?macro=true";
import { default as onlineGtdDAew1ZzMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/[type]/components/registerForm/online.vue?macro=true";
import { default as organizationFeyDj4OZNkMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/[type]/components/registerForm/organization.vue?macro=true";
import { default as wholesalenLl7oGeDLeMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/[type]/components/registerForm/wholesale.vue?macro=true";
import { default as registerynxSlqUYVSMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/[type]/register.vue?macro=true";
import { default as _91id_934lOOs9eLAtMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/afterSales/apply/[id].vue?macro=true";
import { default as _91id_93EdVjVWETvyMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/afterSales/detail/[id].vue?macro=true";
import { default as helperq4r8TRKqidMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/afterSales/helper.ts?macro=true";
import { default as _91id_93bUImsQp2x5Meta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/afterSales/type/[id].vue?macro=true";
import { default as customDatePicker1zwXE3Dfa8Meta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/afterSales/type/customDatePicker.vue?macro=true";
import { default as customPickerewNFVYdHrmMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/afterSales/type/customPicker.vue?macro=true";
import { default as _91type_93mrtZxIG8fUMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/beMember/[type].vue?macro=true";
import { default as cart1b3yXrQ3LvMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/cart.vue?macro=true";
import { default as _91_46_46_46all_93ySZg6XVBgJMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/category/[...all].vue?macro=true";
import { default as GetLocationaHim3TAPWsMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/contact/components/GetLocation.vue?macro=true";
import { default as contactUso7OhaWSxisMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/contact/contactUs.vue?macro=true";
import { default as storeAddressqDeJIUCEgIMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/contact/storeAddress.vue?macro=true";
import { default as websiteTeachEkuYUErmGIMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/contact/websiteTeach.vue?macro=true";
import { default as _91tag_93qZ5sDTDDbBMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/hashtag/[tag].vue?macro=true";
import { default as indexVaY67DSvF4Meta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/index.vue?macro=true";
import { default as _91info_93Y5XFM86Ha0Meta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/information/[info].vue?macro=true";
import { default as _91id_93Gx0Y7zseLwMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/information/detail/[id].vue?macro=true";
import { default as login7Hh33LrgaJMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/login.vue?macro=true";
import { default as membertHUFC5RNZxMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/member.vue?macro=true";
import { default as membershipCard55el2zERSoMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/membership/membershipCard.vue?macro=true";
import { default as openi15TaL5NPDMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/membership/open.vue?macro=true";
import { default as messageowtPvpfBF5Meta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/message.vue?macro=true";
import { default as addressPeruhxSB9CMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/myServices/address.vue?macro=true";
import { default as addressAdd9sjKyAfHB6Meta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/myServices/addressAdd.vue?macro=true";
import { default as membersAreaW2UM2J1iR9Meta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/myServices/membersArea.vue?macro=true";
import { default as rewardsqQeZWqyGREMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/myServices/rewards.vue?macro=true";
import { default as _91id_93K33UmtDkttMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/order/[id].vue?macro=true";
import { default as _91status_93PI2lCnKqhLMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/order/list/[status].vue?macro=true";
import { default as orderConfirmIKTn363Gf7Meta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/order/orderConfirm.vue?macro=true";
import { default as password14h6Inx5xxMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/password.vue?macro=true";
import { default as zfbNqNEcIPcCUMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/payment/zfb.vue?macro=true";
import { default as _91id_932wgT9I2gkZMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/product/[id].vue?macro=true";
import { default as _91term_93PmoaU06wmNMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/protocol/[term].vue?macro=true";
import { default as _91id_93TdVs94958mMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/recipe/[id].vue?macro=true";
import { default as _91id_938mwpOAYXSgMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/recipe/form/[id].vue?macro=true";
import { default as DatePick0L1PA2FWMAMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/recipe/form/DatePick.vue?macro=true";
import { default as PopupSettingYhzhV0MPqOMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/recipe/form/PopupSetting.vue?macro=true";
import { default as index29JECB5w7TMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/recipe/index.vue?macro=true";
import { default as _91status_93dR5RZO2egEMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/recipe/list/[status].vue?macro=true";
import { default as DatePick4HTENGuCTcMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/RecipeManagement/DatePick.vue?macro=true";
import { default as _91status_93fCGwojHJPwMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/RecipeManagement/list/[status].vue?macro=true";
import { default as myRecipe9CWStfA443Meta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/RecipeManagement/myRecipe.vue?macro=true";
import { default as PopupSettingHAFOSeNJDfMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/RecipeManagement/PopupSetting.vue?macro=true";
import { default as postRecipepTa0dWc7JIMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/RecipeManagement/postRecipe.vue?macro=true";
import { default as registerKbjxhBK9meMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/register.vue?macro=true";
import { default as searchYAZWpyl8llMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/searchManagement/search.vue?macro=true";
import { default as deliverysWIglYjIWLMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/shoppingProcess/delivery.vue?macro=true";
import { default as termsAMBskkp1mRMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/shoppingProcess/terms.vue?macro=true";
import { default as conversionToolX7ZgdCRIzhMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/toolManagement/conversionTool.vue?macro=true";
import { default as userCxJdHPSThEMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/user.vue?macro=true";
import { default as userInfo9lws3W2TglMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/pages/userInfo.vue?macro=true";
import { default as component_45stubYKZvfm1zUrMeta } from "/Users/huangkaisong/workspace/ichi-cake-moblie/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_sass@1.79.4_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubYKZvfm1zUr } from "/Users/huangkaisong/workspace/ichi-cake-moblie/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_sass@1.79.4_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "type-activate___en",
    path: "/en/:type()/activate",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/[type]/activate.vue")
  },
  {
    name: "type-activate___tw",
    path: "/:type()/activate",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/[type]/activate.vue")
  },
  {
    name: "type-components-registerForm-online___en",
    path: "/en/:type()/components/registerForm/online",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/[type]/components/registerForm/online.vue")
  },
  {
    name: "type-components-registerForm-online___tw",
    path: "/:type()/components/registerForm/online",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/[type]/components/registerForm/online.vue")
  },
  {
    name: "type-components-registerForm-organization___en",
    path: "/en/:type()/components/registerForm/organization",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/[type]/components/registerForm/organization.vue")
  },
  {
    name: "type-components-registerForm-organization___tw",
    path: "/:type()/components/registerForm/organization",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/[type]/components/registerForm/organization.vue")
  },
  {
    name: "type-components-registerForm-wholesale___en",
    path: "/en/:type()/components/registerForm/wholesale",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/[type]/components/registerForm/wholesale.vue")
  },
  {
    name: "type-components-registerForm-wholesale___tw",
    path: "/:type()/components/registerForm/wholesale",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/[type]/components/registerForm/wholesale.vue")
  },
  {
    name: "type-register___en",
    path: "/en/:type()/register",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/[type]/register.vue")
  },
  {
    name: "type-register___tw",
    path: "/:type()/register",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/[type]/register.vue")
  },
  {
    name: "afterSales-apply-id___en",
    path: "/en/afterSales/apply/:id()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/afterSales/apply/[id].vue")
  },
  {
    name: "afterSales-apply-id___tw",
    path: "/afterSales/apply/:id()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/afterSales/apply/[id].vue")
  },
  {
    name: "afterSales-detail-id___en",
    path: "/en/afterSales/detail/:id()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/afterSales/detail/[id].vue")
  },
  {
    name: "afterSales-detail-id___tw",
    path: "/afterSales/detail/:id()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/afterSales/detail/[id].vue")
  },
  {
    name: "afterSales-helper___en",
    path: "/en/afterSales/helper",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/afterSales/helper.ts")
  },
  {
    name: "afterSales-helper___tw",
    path: "/afterSales/helper",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/afterSales/helper.ts")
  },
  {
    name: "afterSales-type-id___en",
    path: "/en/afterSales/type/:id()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/afterSales/type/[id].vue")
  },
  {
    name: "afterSales-type-id___tw",
    path: "/afterSales/type/:id()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/afterSales/type/[id].vue")
  },
  {
    name: "afterSales-type-customDatePicker___en",
    path: "/en/afterSales/type/customDatePicker",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/afterSales/type/customDatePicker.vue")
  },
  {
    name: "afterSales-type-customDatePicker___tw",
    path: "/afterSales/type/customDatePicker",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/afterSales/type/customDatePicker.vue")
  },
  {
    name: "afterSales-type-customPicker___en",
    path: "/en/afterSales/type/customPicker",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/afterSales/type/customPicker.vue")
  },
  {
    name: "afterSales-type-customPicker___tw",
    path: "/afterSales/type/customPicker",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/afterSales/type/customPicker.vue")
  },
  {
    name: "beMember-type___en",
    path: "/en/beMember/:type()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/beMember/[type].vue")
  },
  {
    name: "beMember-type___tw",
    path: "/beMember/:type()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/beMember/[type].vue")
  },
  {
    name: "cart___en",
    path: "/en/cart",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/cart.vue")
  },
  {
    name: "cart___tw",
    path: "/cart",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/cart.vue")
  },
  {
    name: "category-all___en",
    path: "/en/category/:all(.*)*",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/category/[...all].vue")
  },
  {
    name: "category-all___tw",
    path: "/category/:all(.*)*",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/category/[...all].vue")
  },
  {
    name: "contact-components-GetLocation___en",
    path: "/en/contact/components/GetLocation",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/contact/components/GetLocation.vue")
  },
  {
    name: "contact-components-GetLocation___tw",
    path: "/contact/components/GetLocation",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/contact/components/GetLocation.vue")
  },
  {
    name: "contact-contactUs___en",
    path: "/en/contact/contactUs",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/contact/contactUs.vue")
  },
  {
    name: "contact-contactUs___tw",
    path: "/contact/contactUs",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/contact/contactUs.vue")
  },
  {
    name: "contact-storeAddress___en",
    path: "/en/contact/storeAddress",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/contact/storeAddress.vue")
  },
  {
    name: "contact-storeAddress___tw",
    path: "/contact/storeAddress",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/contact/storeAddress.vue")
  },
  {
    name: "contact-websiteTeach___en",
    path: "/en/contact/websiteTeach",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/contact/websiteTeach.vue")
  },
  {
    name: "contact-websiteTeach___tw",
    path: "/contact/websiteTeach",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/contact/websiteTeach.vue")
  },
  {
    name: "hashtag-tag___en",
    path: "/en/hashtag/:tag()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/hashtag/[tag].vue")
  },
  {
    name: "hashtag-tag___tw",
    path: "/hashtag/:tag()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/hashtag/[tag].vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/index.vue")
  },
  {
    name: "index___tw",
    path: "/",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/index.vue")
  },
  {
    name: "information-info___en",
    path: "/en/information/:info()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/information/[info].vue")
  },
  {
    name: "information-info___tw",
    path: "/information/:info()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/information/[info].vue")
  },
  {
    name: "information-detail-id___en",
    path: "/en/information/detail/:id()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/information/detail/[id].vue")
  },
  {
    name: "information-detail-id___tw",
    path: "/information/detail/:id()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/information/detail/[id].vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/login.vue")
  },
  {
    name: "login___tw",
    path: "/login",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/login.vue")
  },
  {
    name: "member___en",
    path: "/en/member",
    meta: membertHUFC5RNZxMeta || {},
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/member.vue")
  },
  {
    name: "member___tw",
    path: "/member",
    meta: membertHUFC5RNZxMeta || {},
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/member.vue")
  },
  {
    name: "membership-membershipCard___en",
    path: "/en/membership/membershipCard",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/membership/membershipCard.vue")
  },
  {
    name: "membership-membershipCard___tw",
    path: "/membership/membershipCard",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/membership/membershipCard.vue")
  },
  {
    name: "membership-open___en",
    path: "/en/membership/open",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/membership/open.vue")
  },
  {
    name: "membership-open___tw",
    path: "/membership/open",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/membership/open.vue")
  },
  {
    name: "message___en",
    path: "/en/message",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/message.vue")
  },
  {
    name: "message___tw",
    path: "/message",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/message.vue")
  },
  {
    name: "myServices-address___en",
    path: "/en/myServices/address",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/myServices/address.vue")
  },
  {
    name: "myServices-address___tw",
    path: "/myServices/address",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/myServices/address.vue")
  },
  {
    name: "myServices-addressAdd___en",
    path: "/en/myServices/addressAdd",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/myServices/addressAdd.vue")
  },
  {
    name: "myServices-addressAdd___tw",
    path: "/myServices/addressAdd",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/myServices/addressAdd.vue")
  },
  {
    name: "myServices-membersArea___en",
    path: "/en/myServices/membersArea",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/myServices/membersArea.vue")
  },
  {
    name: "myServices-membersArea___tw",
    path: "/myServices/membersArea",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/myServices/membersArea.vue")
  },
  {
    name: "myServices-rewards___en",
    path: "/en/myServices/rewards",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/myServices/rewards.vue")
  },
  {
    name: "myServices-rewards___tw",
    path: "/myServices/rewards",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/myServices/rewards.vue")
  },
  {
    name: "order-id___en",
    path: "/en/order/:id()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/order/[id].vue")
  },
  {
    name: "order-id___tw",
    path: "/order/:id()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/order/[id].vue")
  },
  {
    name: "order-list-status___en",
    path: "/en/order/list/:status()",
    meta: _91status_93PI2lCnKqhLMeta || {},
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/order/list/[status].vue")
  },
  {
    name: "order-list-status___tw",
    path: "/order/list/:status()",
    meta: _91status_93PI2lCnKqhLMeta || {},
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/order/list/[status].vue")
  },
  {
    name: "order-orderConfirm___en",
    path: "/en/order/orderConfirm",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/order/orderConfirm.vue")
  },
  {
    name: "order-orderConfirm___tw",
    path: "/order/orderConfirm",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/order/orderConfirm.vue")
  },
  {
    name: "password___en",
    path: "/en/password",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/password.vue")
  },
  {
    name: "password___tw",
    path: "/password",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/password.vue")
  },
  {
    name: "payment-zfb___en",
    path: "/en/payment/zfb",
    meta: zfbNqNEcIPcCUMeta || {},
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/payment/zfb.vue")
  },
  {
    name: "payment-zfb___tw",
    path: "/payment/zfb",
    meta: zfbNqNEcIPcCUMeta || {},
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/payment/zfb.vue")
  },
  {
    name: "product-id___en",
    path: "/en/product/:id()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/product/[id].vue")
  },
  {
    name: "product-id___tw",
    path: "/product/:id()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/product/[id].vue")
  },
  {
    name: "protocol-term___en",
    path: "/en/protocol/:term()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/protocol/[term].vue")
  },
  {
    name: "protocol-term___tw",
    path: "/protocol/:term()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/protocol/[term].vue")
  },
  {
    name: "recipe-id___en",
    path: "/en/recipe/:id()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/recipe/[id].vue")
  },
  {
    name: "recipe-id___tw",
    path: "/recipe/:id()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/recipe/[id].vue")
  },
  {
    name: "recipe-form-id___en",
    path: "/en/recipe/form/:id()",
    meta: _91id_938mwpOAYXSgMeta || {},
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/recipe/form/[id].vue")
  },
  {
    name: "recipe-form-id___tw",
    path: "/recipe/form/:id()",
    meta: _91id_938mwpOAYXSgMeta || {},
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/recipe/form/[id].vue")
  },
  {
    name: "recipe-form-DatePick___en",
    path: "/en/recipe/form/DatePick",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/recipe/form/DatePick.vue")
  },
  {
    name: "recipe-form-DatePick___tw",
    path: "/recipe/form/DatePick",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/recipe/form/DatePick.vue")
  },
  {
    name: "recipe-form-PopupSetting___en",
    path: "/en/recipe/form/PopupSetting",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/recipe/form/PopupSetting.vue")
  },
  {
    name: "recipe-form-PopupSetting___tw",
    path: "/recipe/form/PopupSetting",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/recipe/form/PopupSetting.vue")
  },
  {
    name: "recipe___en",
    path: "/en/recipe",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/recipe/index.vue")
  },
  {
    name: "recipe___tw",
    path: "/recipe",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/recipe/index.vue")
  },
  {
    name: "recipe-list-status___en",
    path: "/en/recipe/list/:status()",
    meta: _91status_93dR5RZO2egEMeta || {},
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/recipe/list/[status].vue")
  },
  {
    name: "recipe-list-status___tw",
    path: "/recipe/list/:status()",
    meta: _91status_93dR5RZO2egEMeta || {},
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/recipe/list/[status].vue")
  },
  {
    name: "RecipeManagement-DatePick___en",
    path: "/en/RecipeManagement/DatePick",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/RecipeManagement/DatePick.vue")
  },
  {
    name: "RecipeManagement-DatePick___tw",
    path: "/RecipeManagement/DatePick",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/RecipeManagement/DatePick.vue")
  },
  {
    name: "RecipeManagement-list-status___en",
    path: "/en/RecipeManagement/list/:status()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/RecipeManagement/list/[status].vue")
  },
  {
    name: "RecipeManagement-list-status___tw",
    path: "/RecipeManagement/list/:status()",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/RecipeManagement/list/[status].vue")
  },
  {
    name: "RecipeManagement-myRecipe___en",
    path: "/en/RecipeManagement/myRecipe",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/RecipeManagement/myRecipe.vue")
  },
  {
    name: "RecipeManagement-myRecipe___tw",
    path: "/RecipeManagement/myRecipe",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/RecipeManagement/myRecipe.vue")
  },
  {
    name: "RecipeManagement-PopupSetting___en",
    path: "/en/RecipeManagement/PopupSetting",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/RecipeManagement/PopupSetting.vue")
  },
  {
    name: "RecipeManagement-PopupSetting___tw",
    path: "/RecipeManagement/PopupSetting",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/RecipeManagement/PopupSetting.vue")
  },
  {
    name: "RecipeManagement-postRecipe___en",
    path: "/en/RecipeManagement/postRecipe",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/RecipeManagement/postRecipe.vue")
  },
  {
    name: "RecipeManagement-postRecipe___tw",
    path: "/RecipeManagement/postRecipe",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/RecipeManagement/postRecipe.vue")
  },
  {
    name: "register___en",
    path: "/en/register",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/register.vue")
  },
  {
    name: "register___tw",
    path: "/register",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/register.vue")
  },
  {
    name: "searchManagement-search___en",
    path: "/en/searchManagement/search",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/searchManagement/search.vue")
  },
  {
    name: "searchManagement-search___tw",
    path: "/searchManagement/search",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/searchManagement/search.vue")
  },
  {
    name: "shoppingProcess-delivery___en",
    path: "/en/shoppingProcess/delivery",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/shoppingProcess/delivery.vue")
  },
  {
    name: "shoppingProcess-delivery___tw",
    path: "/shoppingProcess/delivery",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/shoppingProcess/delivery.vue")
  },
  {
    name: "shoppingProcess-terms___en",
    path: "/en/shoppingProcess/terms",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/shoppingProcess/terms.vue")
  },
  {
    name: "shoppingProcess-terms___tw",
    path: "/shoppingProcess/terms",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/shoppingProcess/terms.vue")
  },
  {
    name: "toolManagement-conversionTool___en",
    path: "/en/toolManagement/conversionTool",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/toolManagement/conversionTool.vue")
  },
  {
    name: "toolManagement-conversionTool___tw",
    path: "/toolManagement/conversionTool",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/toolManagement/conversionTool.vue")
  },
  {
    name: "user___en",
    path: "/en/user",
    meta: userCxJdHPSThEMeta || {},
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/user.vue")
  },
  {
    name: "user___tw",
    path: "/user",
    meta: userCxJdHPSThEMeta || {},
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/user.vue")
  },
  {
    name: "userInfo___en",
    path: "/en/userInfo",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/userInfo.vue")
  },
  {
    name: "userInfo___tw",
    path: "/userInfo",
    component: () => import("/Users/huangkaisong/workspace/ichi-cake-moblie/pages/userInfo.vue")
  },
  {
    name: component_45stubYKZvfm1zUrMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubYKZvfm1zUr
  },
  {
    name: component_45stubYKZvfm1zUrMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubYKZvfm1zUr
  },
  {
    name: component_45stubYKZvfm1zUrMeta?.name,
    path: "/en-US-sitemap.xml",
    component: component_45stubYKZvfm1zUr
  },
  {
    name: component_45stubYKZvfm1zUrMeta?.name,
    path: "/zh-Hant-sitemap.xml",
    component: component_45stubYKZvfm1zUr
  }
]