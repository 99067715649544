import { defineNuxtPlugin } from '#app'
import dayjs from '#build/dayjs.imports.mjs'

declare module '#app' {
  interface NuxtApp {
    $dayjs: typeof dayjs
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $dayjs: typeof dayjs
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $dayjs: typeof dayjs
  }
}

export default defineNuxtPlugin(async nuxtApp => nuxtApp.provide('dayjs', dayjs))
