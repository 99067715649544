import { consola } from 'consola'

import { jwtDecode } from 'jwt-decode'

export default defineNuxtPlugin((nuxtApp) => {
  const logger = consola.create({
    formatOptions:
        {
          date: false,
          columns: 2,
        },
  }).withTag('auth-plugin')

  // 定义全局的用户状态，初始值为 null
  const user = useState<_JwtPayload | null>('user', () => null)

  // 读取 cookie 中的 token
  const token = useCookie('ichi_token').value

  // 如果 token 存在，则解析 token 并更新用户信息
  if (typeof token === 'string') {
    try {
      user.value = jwtDecode<_JwtPayload>(token)

      logger.info('User:', user.value.cName)
    }
    catch (error) {
      logger.warn('Invalid token:', error)
    }
  }

  // 提供一个更新用户信息的函数
  nuxtApp.provide('setUser', (newToken: string) => {
    if (!newToken) {
      user.value = null
    }
    else {
      try {
        user.value = jwtDecode<_JwtPayload>(newToken || '')
        logger.success('User updated:', user.value)
      }
      catch (error) {
        user.value = null
        logger.error('Invalid token:', error)
      }
    }
  })
})
