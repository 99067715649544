<script setup lang="ts">
import { emitter } from './utils/mitt'

const categories = useState<_CategoryStateItem[]>('Categories', () => [])

/** 节日 */
const holidayStore = useState('_HolidayStore', () => [])

await useAsyncData('ichi_Categories_store', () =>
  $fetch<_ResponseType<_Pagination<_CategoryStateItem[]>>>(
    '/api/category/list',
    {
      method: 'POST',
      params: {
        size: 999999,
        publishStatus: 1,
      },
      onResponse({ response }) {
        const { code, data } = response._data
        if (code === 200) {
          categories.value = data.records
        }
      },
    },
  ))

await useAsyncData<_ResponseType<_Pagination<any>>>('holidays', () => $fetch('/api/holidayAttribute/listForCustomer', {
  method: 'POST',
  params: {
    size: 999999,
  },
  retry: 2,
  retryDelay: 1000,
  onResponse({ response }) {
    const { code, data } = response._data
    if (code === 200) {
      holidayStore.value = data.records
    }
  },
}))

// 挂载 pinia
useAuthStore()
const cart = useCartStore()

await cart.syncCart()
function handleIframeMessage(event: MessageEvent) {
  if (event.origin.includes('localhost') || event.origin.includes('192.168.') || event.origin.includes('admin.ichi-cake')) {
    const { data, type } = event.data
    if (type !== 'page-define')
      return

    emitter.emit('bannerChange', data)
  }
}

function handleClickA(event: MouseEvent) {
  event.preventDefault()
  event.stopPropagation()
  event.stopImmediatePropagation()
  const el = event.currentTarget as HTMLAnchorElement

  // 获取自定义 data 属性
  const eventType = el.getAttribute('data-event-type')
  if (eventType && eventType === 'page-define') {
    const eventId = el.getAttribute('data-event-id')
    const imgTypeId = el.getAttribute('data-type-id')

    // 向父窗口发送消息
    window.parent.postMessage({
      type: eventType,
      id: eventId,
      imgTypeId,
    }, '*')
  }
}

function handleClickImg(event: MouseEvent) {
  event.preventDefault()
  event.stopPropagation()
  event.stopImmediatePropagation()
  const el = event.currentTarget as HTMLImageElement

  // 获取自定义 data 属性
  let eventType = el.getAttribute('data-event-type')
  let eventId = el.getAttribute('data-event-id')
  let imgTypeId = el.getAttribute('data-type-id')
  if (!eventType) {
    // 如果图片没有 data-event-type 属性，则尝试获取父元素 a 标签的 data-event-type 属性
    const parentEl = el.parentElement
    if (parentEl && parentEl.tagName === 'A') {
      eventType = parentEl.getAttribute('data-event-type')
      eventId = parentEl.getAttribute('data-event-id')
      imgTypeId = parentEl.getAttribute('data-type-id')
    }
  }

  if (eventType && (eventType === 'page-define')) {
    window.parent.postMessage({
      type: eventType,
      id: eventId,
      imgTypeId,
    }, '*')
  }
}

onMounted(() => {
  window.addEventListener('message', handleIframeMessage, false)

  nextTick(() => {
    // 如果页面被包含
    if (isInsideIframe) {
      // 阻止所有路由跳转
      window.history.pushState = () => {}

      document.querySelectorAll('a').forEach((el) => {
        el.addEventListener('click', handleClickA)
      })

      document.querySelectorAll('img').forEach((el) => {
        el.addEventListener('click', handleClickImg)
      })
    }
  })
})

onUnmounted(() => {
  window.removeEventListener('message', handleIframeMessage, false)
  document.querySelectorAll('a').forEach((el) => {
    el.removeEventListener('click', handleClickA)
  })
})
</script>

<template>
  <NuxtLoadingIndicator color="#f3f3f3" :throttle="300" :duration="500" :height="2" />
  <NuxtRouteAnnouncer />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

/* 设置滚动条 */
::-webkit-scrollbar {
  width: 1px;
  height: 5px;
}
html,
body {
  /* ios 禁止橡皮筋效果 */
  overscroll-behavior-y: none;
  -webkit-overflow-scrolling: touch;
  /* 设置滚动条 */
  scrollbar-width: thin;
  scrollbar-color: #d9d9d9 transparent;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 5px;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: transparent;
}
</style>
