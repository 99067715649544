export const isClient = typeof window !== 'undefined' && typeof document !== 'undefined'
export const isWorker = typeof WorkerGlobalScope !== 'undefined' && globalThis instanceof WorkerGlobalScope
export const isDef = <T = any>(val?: T): val is T => typeof val !== 'undefined'
export const notNullish = <T = any>(val?: T | null | undefined): val is T => val != null
export function assert(condition: boolean, ...infos: any[]) {
  if (!condition)
    console.warn(...infos)
}
const toString = Object.prototype.toString
export function isObject(val: any): val is object {
  return toString.call(val) === '[object Object]'
}

export const isNumber = (val: any): val is number => typeof val === 'number'
export const now = () => Date.now()
export const timestamp = () => +Date.now()
export const clamp = (n: number, min: number, max: number) => Math.min(max, Math.max(min, n))
export function noop() { }
export function rand(min: number, max: number) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}
export const hasOwn = <T extends object, K extends keyof T>(val: T, key: K): key is K => Object.prototype.hasOwnProperty.call(val, key)

export const isIOS = /* #__PURE__ */ getIsIOS()

function getIsIOS() {
  return isClient && window?.navigator?.userAgent && (
    (/iP(?:ad|hone|od)/.test(window.navigator.userAgent))
    // The new iPad Pro Gen3 does not identify itself as iPad, but as Macintosh.
    // https://github.com/vueuse/vueuse/issues/3577
    || (window?.navigator?.maxTouchPoints > 2 && /iPad|Macintosh/.test(window?.navigator.userAgent))
  )
}

export const isInsideIframe = getIsInsideIframe()

function getIsInsideIframe() {
  try {
    // 如果当前窗口的 self 和 top 不是同一个窗口，则说明当前页面在一个 iframe 中
    return isClient && (window.self !== window.top)
  }
  catch (e: any) {
    console.error(e)
    // 如果访问 window.self 或 window.top 抛出异常，也说明当前页面在一个 iframe 中
    return true
  }
}


/**
 * 深度对比两个数组对象是否一致
 * @param a array
 * @param b array
 * @returns boolean
 */
export function isEqual<T>(a: T[], b: T[]): boolean {
  // 如果长度不相等，直接返回 false
  if (a.length !== b.length) {
    return false
  }

  // 使用递归的方式进行深度对比
  const isObject = (obj: any) => obj !== null && typeof obj === 'object'

  const deepEqual = (obj1: any, obj2: any): boolean => {
    // 如果两个对象严格相等，直接返回 true
    if (Object.is(obj1, obj2)) {
      return true
    }

    // 如果两个对象的类型不同，直接返回 false
    if (typeof obj1 !== typeof obj2 || Array.isArray(obj1) !== Array.isArray(obj2)) {
      return false
    }

    // 如果是数组，递归比较每一项
    if (Array.isArray(obj1)) {
      if (obj1.length !== obj2.length) {
        return false
      }
      return obj1.every((item, index) => deepEqual(item, obj2[index]))
    }

    // 如果是对象，比较每一个键和值
    if (isObject(obj1) && isObject(obj2)) {
      const keys1 = Object.keys(obj1)
      const keys2 = Object.keys(obj2)

      // 键的数量不同，返回 false
      if (keys1.length !== keys2.length) {
        return false
      }

      // 递归比较每个键对应的值
      return keys1.every(key => keys2.includes(key) && deepEqual(obj1[key], obj2[key]))
    }

    // 其他情况直接返回 false
    return false
  }

  // 对比两个数组中的每一项
  return a.every((item, index) => deepEqual(item, b[index]))
}