export const useAuthStore = defineStore('Auth', {
  state(): {
    userinfo: UserStore | undefined
    level: MbType
    address: _ShopAddress[]
    ichiToken: string
  } {
    return {
      userinfo: undefined,
      level: 'o', // 默认会员等级
      address: [],
      ichiToken: '',
    }
  },
  getters: {
    /**
     * 获取默认地址
     * @returns {_ShopAddress} 默认地址
     */
    defaultAddress(): _ShopAddress {
      return this.address?.find(item => item.isdefault) as _ShopAddress || {}
    },
    // 是否登录
    isLogin(): boolean {
      return !!this.userinfo
    },
    priceFiled(): string {
      const { fullPath } = useRoute()

      // 商品列表显示价格字段
      const listFileds: any = {
        O: 'uprice',
        M: 'aprice',
        B3: 'dprice',
        V: 'aprice',
        IN: 'uprice',
        RE: 'uprice',
      }
      // other level show price filed
      const otherFileds: any = {
        O: 'aprice', // 網店會員
        M: 'bprice', // 基本會員
        B3: 'eprice', // 批發會員
        V: 'bprice', // 永久會員
        IN: 'aprice', // 機構會員
        RE: 'eprice', // 代理會員
      }

      const levelChar: string = this.level.toLocaleUpperCase()

      if (fullPath.startsWith('/category') || fullPath.startsWith('/hashTag')) {
        // 取商品列表显示价格字段
        return listFileds[levelChar] || 'uprice'
      }
      else {
        return otherFileds[levelChar] || 'aprice'
      }
    },
  },
  actions: {
    /** 获取用户信息 */
    async getUserInfo() {
      const { formatQuery } = useUseBeforeRequest()
      const { success, data } = await useHttp.post<UserStore>('/ichi_api/wCustomer/select', null, {
        params: {
          MESSAGE: formatQuery({
            customerId: this.userinfo?.id,
            custCode: this.userinfo?.custCode,
          }),
        },
      })
      if (success) {
        await this.syncIntegral()
        await this.patchUser(data)
      }
    },
    async patchUser(data: any) {
      const { data: token } = await useHttp.patch<string>('/api/jwt', { ...this.userinfo, ...data })
      this.userinfo = { ...this.userinfo, ...data }

      const nuxtApp = useNuxtApp()
      nuxtApp.$setUser(token!)
    },
    // 登录
    async login(userinfo: UserStore) {
      this.userinfo = userinfo
      this.level = userinfo.mbType!

      await this.syncIntegral()

      const { data } = await useHttp.post<string>('/api/jwt', this.userinfo)
      const nuxtApp = useNuxtApp()
      nuxtApp.$setUser(data!)
    },
    // 登出
    logout() {
      this.userinfo = undefined
      this.level = 'o'
      this.ichiToken = ''
      this.clearAddress()
      useCookie('ichi_token').value = null
    },
    async syncIntegral() {
      const { formatQuery } = useUseBeforeRequest()
      const { success, data } = await useHttp.post<_UserMemberPoint>('/ichi_api/point/memberPointEnquiry', null, {
        params: {
          MESSAGE: formatQuery({
            custCode: this.userinfo!.custCode,
          }),
        },
        retry: 3,
        retryDelay: 1000,
        timeout: 150000,
      })
      if (success) {
        this.userinfo!.integral = Number.parseInt(data!.pointBal)
        this.userinfo!.frozenPoint = data!.frozenPoint
      }
    },
    async fetchAddr() {
      const { formatQuery } = useUseBeforeRequest()
      const { success, data } = await useHttp.post<_ShopAddress[]>('/ichi_api/wCustmoerAddress/list', null, {
        params: {
          MESSAGE: formatQuery({
            customerId: this.userinfo!.id,
          }),
        },
      })
      if (success) {
        this.setAddress(data!)
      }
    },
    setAddress(address: _ShopAddress[]) {
      this.address = address
    },
    clearAddress() {
      this.address = []
    },
  },
  hydrate(_storeState) {
    const initialState = localStorage.getItem('ichi_Auth_cake')
    if (initialState) {
      const { level, userinfo } = JSON.parse(initialState)
      _storeState.level = level
      _storeState.userinfo = userinfo
    }
  },
  persist: [
    {
      debug: true,
      pick: ['userinfo', 'level'],
      storage: piniaPluginPersistedstate.localStorage(),
    },
  ],
})
