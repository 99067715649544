import { Locale } from 'vant'
import en from 'vant/es/locale/lang/en-US'
import tw from 'vant/es/locale/lang/zh-CN'

export default defineNuxtPlugin(() => {
  // 载入 vant 语言包
  Locale.use('tw', tw)
  Locale.use('en', en)

  if (import.meta.client) {
    const i18n = useNuxtApp().$i18n
    const { setLocale, locale } = i18n

    const lang = localStorage.getItem('lang')

    if (lang) {
      setLocale(lang)
      Locale.use(lang)
    }
    else {
      setLocale(locale.value)
      Locale.use(locale.value)
    }
  }
})
