import { jwtDecode } from 'jwt-decode'

export default defineNuxtPlugin((_nuxtApp) => {
  // const ichi_token = useCookie('ichi_token')

  // if (ichi_token.value) {
  //   const payload = jwtDecode<_JwtPayload>(ichi_token.value)
  //   nuxtApp.provide('userinfo', payload)
  // }
  // else {
  //   nuxtApp.provide('userinfo', null)
  // }

  addRouteMiddleware('global-middleware', () => {
    // 每次路由改变时运行的全局中间件
    // todo do something
  }, { global: true })
})
