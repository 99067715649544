import type { Emitter } from 'mitt'
import mitt from 'mitt'

/** 全局公共事件需要在此处添加类型  需要满足 Record<EventType, unknown> */
interface Events {
  bannerChange: _BannerList[]
  [key: string]: unknown
  [key: symbol]: unknown
}

export const emitter: Emitter<Events> = mitt<Events>()
