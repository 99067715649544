export function useUseBeforeRequest() {
  const i18n = useNuxtApp().$i18n
  const { locale } = i18n
  const formatQuery: (body: any) => string = (body) => {
    body.language = locale.value === 'en' ? 'en' : 'cn'

    return JSON.stringify({ requestParam: body }, null)
  }

  return {
    formatQuery,
  }
}
