export const useCartStore = defineStore('Cart', {
  state: (): {
    cart: _CartItem[]
  } => ({
    cart: [],
  }),
  getters: {
    total(): number {
      return (this.cart || []).reduce((acc, cur) => math.add(acc, cur.count), 0)
    },
    totalPrice(): number {
      const { priceFiled } = useAuthStore()
      return (this.cart || []).reduce((acc, cur) => {
        return math.add(acc, math.multiply(Number(cur.goods[priceFiled]), cur.count))
      }, 0)
    },
  },
  actions: {
    async putCart(cartList: _CartItem[]) {
      return new Promise((resolve) => {
        (async () => {
          const authStore = useAuthStore()
          const user = useState<_JwtPayload | null>('user', () => null)

          const userinfo = computed(() => {
            try {
              return user.value
            }
            catch (error) {
              console.error(error)
              return authStore.userinfo
            }
          })

          if (!userinfo.value) {
            const { code, data } = await useHttp.post<any>('/api/cart/sync', cartList)
            if (code === 200) {
              this.cart = data
            }
            resolve(true)
            return
          }

          const { formatQuery } = useUseBeforeRequest()
          // 获取发生改变的购物车数据
          const cart = this.cart
          const changeList = cartList.filter((item) => {
            return item.count !== cart.find(item2 => item2.id === item.id)!.count
          })
          if (changeList.length) {
            changeList.map(async (item) => {
              const data = {
                id: item.id,
                count: item.count,
              }

              const { success } = await useHttp.post<any>('/ichi_api/shoppingCart/edit', null, {
                params: {
                  MESSAGE: formatQuery(data),
                },
              })

              if (success) {
                this.changeCartItem(item.id, item.count)
              }
              resolve(success)
            })
          }
          else {
            resolve(true)
          }
        })()
      })
    },
    async syncCart() {
      const authStore = useAuthStore()
      const user = useState<_JwtPayload | null>('user', () => null)

      const userinfo = computed(() => {
        try {
          return user.value
        }
        catch (error) {
          console.error(error)
          return authStore.userinfo
        }
      })

      const isLogin = computed(() => !!userinfo.value?.id)
      const { formatQuery } = useUseBeforeRequest()
      if (isLogin.value) {
        const { data } = await useFetch<_ResponseType<_CartItem[]>>('/api/shoppingCart/getShoppingCartList', {
          params: {
            MESSAGE: formatQuery({
              custCode: userinfo.value?.custCode,
            }),
          },
        })
        if (data.value && data.value.success) {
          this.cart = data.value.data!
        }
        else {
          this.cart = []
        }
      }
      else {
        const { data } = await useFetch<_ResponseType<_CartItem[]>>('/api/cart/sync', { deep: false })
        if (data.value && data.value.success) {
          this.cart = data.value.data!
        }
      }
    },
    addCart(item: _CartItem) {
      // 如果购物车已存在商品， 则修改数量 判断依据 scode origin cookbookId 3项
      const isExist = this.cart.some((cartItem) => {
        return (
          item.scode === cartItem.scode
          && item.origin === cartItem.origin
        )
      })
      if (!isExist) {
        this.cart.push(item)
      }
      else {
        const cartItem = this.cart.find((cartItem) => {
          return (
            item.scode === cartItem.scode
            && item.origin === cartItem.origin
          )
        })
        if (cartItem) {
          cartItem.count += item.count
        }
      }
    },
    removeCart(id: number) {
      this.cart = this.cart.filter(item => item.id !== id)
    },
    changeCartItem(id: number, count: number) {
      const item = this.cart.find(item => item.id === id)
      if (item) {
        item.count = count
      }
    },
  },
  hydrate(storeState) {
    const initialState = localStorage.getItem('ichi_Cart_cake')
    if (initialState) {
      const { cart } = JSON.parse(initialState)
      storeState.cart = cart
    }
  },
  persist: [
    {
      debug: true,
      storage: piniaPluginPersistedstate.localStorage(),
    },
  ],
})
