import type { FetchOptions as _FetchOptions, FetchResponse, SearchParameters } from 'ofetch'
import { consola } from 'consola'
import { showToast } from 'vant'

export interface ResOptions<T> {
  data: T
  code: number
  msg: string
  success: boolean
}

export interface _ResponseType<T = any> {
  data?: T
  code?: number
  msg?: string
  success?: boolean
  error?: string
  exception?: string
  message?: string
  path?: string
  status?: number
  timestamp?: string
}

function handleError<T>(response: FetchResponse<ResOptions<T>> & FetchResponse<_ResponseType>) {
  consola.error(response)

  const err = (text: string) => {
    showToast({
      message: text,
      type: 'fail',
      wordBreak: 'break-all',
    })
  }
  if (!response._data) {
    err('请求超时，服务器无响应！')
    return
  }
  const handleMap: { [key: number]: () => void } = {
    404: () => err('服务器资源不存在'),
    500: () => err('服务器内部错误'),
    403: () => err('没有权限访问该资源'),
    400: () => {
      err(response._data?.message || response._data?.error || '请求参数错误')
    },
  }
  handleMap[response.status] ? handleMap[response.status]() : err('未知错误！')
}

// get方法传递数组形式参数
function paramsSerializer(params?: SearchParameters) {
  if (!params)
    return

  const query = params
  Object.entries(query).forEach(([key, val]) => {
    if (typeof val === 'object' && Array.isArray(val) && val !== null) {
      query[`${key}[]`] = toRaw(val).map((v: any) => JSON.stringify(v))
      delete query[key]
    }
  })

  return query
}

const customFetch = $fetch.create({
  // 请求拦截器
  onRequest({ options }) {
    // get方法传递数组形式参数
    options.params = paramsSerializer(options.params)
    // 添加请求头,没登录不携带token
    options.headers = new Headers(options.headers)
  },
  // 响应拦截
  onResponse({ response }) {
    if (response.status === 200) {
      return response
    }
    else {
      handleError(response)
      return Promise.reject(response._data)
    }
    // 成功返回
    return response._data
  },
  // 错误处理
  onResponseError({ response }) {
    handleError(response)
    return Promise.reject(response?._data ?? null)
  },
})

// 自动导出
export const useHttp = {
  get: <T = _ResponseType<any>>(url: string, params?: any, options?: _FetchOptions) => {
    return customFetch<_ResponseType<T>>(url, { ...options, method: 'get', params })
  },

  post: <T = _ResponseType<any>>(url: string, body?: any, options?: _FetchOptions) => {
    return customFetch<_ResponseType<T>>(url, { ...options, method: 'post', body })
  },

  put: <T = any>(url: string, body?: any, options?: _FetchOptions) => {
    return customFetch<_ResponseType<T>>(url, { ...options, method: 'put', body })
  },

  delete: <T = any>(url: string, body?: any, options?: _FetchOptions) => {
    return customFetch<_ResponseType<T>>(url, { ...options, method: 'delete', body })
  },
  patch: <T = any>(url: string, body?: any, options?: _FetchOptions) => {
    return customFetch<_ResponseType<T>>(url, { ...options, method: 'patch', body })
  },
}
